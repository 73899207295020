var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('BButton', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.300",
      value: 'rgba(255, 159, 67, 0.15)',
      expression: "'rgba(255, 159, 67, 0.15)'",
      modifiers: {
        "300": true
      }
    }],
    staticClass: "rounded w-100 px-1",
    attrs: {
      "id": "promo-code",
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.onTogglePopover(true);
      }
    }
  }, [_c('div', {
    staticClass: "text-body float-left d-flex-center"
  }, [_c('IAmIcon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "percent",
      "size": "22"
    }
  }), _c('span', {
    staticClass: "text-nowrap fw-700 font-medium-1"
  }, [_vm._v(" " + _vm._s(_vm.promoCodes.length > 0 ? _vm.promoCodes.length : '') + " " + _vm._s(_vm.$t('flight.promoCode')) + " ")])], 1)]), _c('BPopover', {
    ref: "refPopover",
    attrs: {
      "id": "popover-promo-code",
      "variant": "warning",
      "target": "promo-code",
      "placement": "top",
      "triggers": "focus hover",
      "boundary": "window",
      "show": _vm.showPopover
    },
    on: {
      "update:show": function updateShow($event) {
        _vm.showPopover = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex justify-content-between align-items-center bg-warning"
        }, [_vm._v(" " + _vm._s(_vm.$t('flight.promoCode')) + " "), _c('span', {
          staticClass: "p-25 cursor-pointer",
          on: {
            "click": _vm.onTogglePopover
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "XIcon",
            "size": "20"
          }
        })], 1)])];
      },
      proxy: true
    }])
  }, [_c('BContainer', [_c('BRow', {
    staticClass: "mb-50 px-0"
  }, [_c('BCol', {
    staticClass: "px-50"
  }, [_c('BFormInput', {
    staticStyle: {
      "min-width": "80px"
    },
    attrs: {
      "value": _vm.promotionCode,
      "placeholder": "Nhập vào",
      "formatter": _vm.upperCaseFormatter
    },
    on: {
      "change": function change(val) {
        return _vm.handleChange(val);
      }
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }