<template>
  <div>
    <BButton
      id="promo-code"
      v-ripple.300="'rgba(255, 159, 67, 0.15)'"
      class="rounded w-100 px-1"
      variant="outline-primary"
      @click="onTogglePopover(true)"
    >
      <div class="text-body float-left d-flex-center">
        <IAmIcon
          icon="percent"
          size="22"
          class="mr-50"
        />
        <span class="text-nowrap fw-700 font-medium-1">
          {{ promoCodes.length > 0 ? promoCodes.length : '' }}
          {{ $t('flight.promoCode') }}
        </span>
      </div>
    </BButton>

    <BPopover
      id="popover-promo-code"
      ref="refPopover"
      variant="warning"
      target="promo-code"
      placement="top"
      triggers="focus hover"
      boundary="window"
      :show.sync="showPopover"
    >
      <template #title>
        <div class="d-flex justify-content-between align-items-center bg-warning">
          {{ $t('flight.promoCode') }}
          <span
            class="p-25 cursor-pointer"
            @click="onTogglePopover"
          >
            <feather-icon
              icon="XIcon"
              size="20"
            />
          </span>
        </div>
      </template>

      <BContainer>
        <BRow class="mb-50 px-0">
          <BCol class="px-50">
            <BFormInput
              :value="promotionCode"
              placeholder="Nhập vào"
              :formatter="upperCaseFormatter"
              style="min-width: 80px;"
              @change="val => handleChange(val)"
            />
          </BCol>
        </BRow>
      </BContainer>
    </BPopover>
  </div>
</template>

<script>
import {
  BButton,
  BContainer,
  BPopover,
  BRow,
  BCol,
  BFormInput,
} from 'bootstrap-vue'
import { VueAutosuggest } from 'vue-autosuggest'
import { ref } from '@vue/composition-api'
import cloneDeep from 'lodash/cloneDeep'

// import store from '@/store'

import { upperCaseFormatter } from '@core/comp-functions/forms/formatter-input'

// import useBookingHandle from '@flightv2/useBookingHandle'

export default {
  components: {
    BButton,
    BContainer,
    BPopover,
    BRow,
    BCol,
    BFormInput,
    VueAutosuggest,
  },
  props: {
    promoCodes: {
      type: [Array, null],
      default: () => [],
    },
    airlines: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    // const { FLIGHT_APP_STORE_MODULE_NAME } = useBookingHandle()

    function handleChange(value) {
      const promoCodesToUpdate = cloneDeep(props.promoCodes)
      // const airlineIndex = promoCodesToUpdate.findIndex(item => item.airline === airline)
      // if (airlineIndex > -1) {
      //   if (code.length > 0) {
      //     promoCodesToUpdate[airlineIndex].code = code
      //   } else {
      //     promoCodesToUpdate.splice(airlineIndex, 1)
      //     // emit('update:airlines', props.airlines.filter(item => item !== airline))
      //   }
      // } else {
      //   promoCodesToUpdate.push({
      //     airline,
      //     code,
      //   })
      //   if (!props.airlines.includes(airline)) {
      //     // emit('update:airlines', [...props.airlines, airline])
      //   }
      // }
      emit('update:promoCodes', promoCodesToUpdate)
    }

    const promotionCode = ref('')

    // ANCHOR handle popover
    const showPopover = ref(false)
    const onTogglePopover = (status = null) => {
      if (status === true || status === false) {
        showPopover.value = status
        return
      }
      showPopover.value = !showPopover.value
    }

    return {
      showPopover,
      onTogglePopover,
      promotionCode,
      handleChange,
      upperCaseFormatter,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
#autosuggest-autosuggest__results {
  .autosuggest__results {
    width: 400px
  }
}
</style>
